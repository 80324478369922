<template>
  <v-card height="100%">
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-cards-heart</v-icon>
      Posts Mais Curtidos
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            class="mr-2"
            @click="dialogCurtidas = true"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>Todos Posts</span>
      </v-tooltip>
      <DialogCurtidas
        v-if="dialogCurtidas"
        :dialogCurtidas.sync="dialogCurtidas"
      />
      <DialogPostCurtida
        v-if="dialogPostCurtida"
        :dialogPostCurtida.sync="dialogPostCurtida"
        :post_id="post_id"
      />
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text v-if="top_posts_curtidas.length">
      <v-data-table
        :headers="headers"
        :items="top_posts_curtidas"
        :loading="loading"
        hide-default-footer
        disable-sort
        @click:row="openPost"
        class="data-tables data-tables__row-click"
      >
        <template v-slot:header.qtde="{ header }">
          <div class="d-flex align-center justify-end">
            <v-icon left>
              mdi-cards-heart
            </v-icon>
            {{ header.text }}
          </div>
        </template>
        <template v-slot:item.conteudo="{ item }">
          {{ item.conteudo | excerpt(30) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhum Post Curtido Ainda
    </v-card-text>
  </v-card>
</template>

<script>
import { topPostsCurtidas } from "@/api/admin/graficos/postscurtidas.js";

export default {
  name: "BoxTotalPosts",

  components: {
    DialogCurtidas: () => import("./DialogCurtidas.vue"),
    DialogPostCurtida: () => import("./DialogPostCurtida.vue"),
  },

  data() {
    return {
      top_posts_curtidas: [],
      loading: false,
      dialogCurtidas: false,
      dialogPostCurtida: false,
      post: {},
      post_id: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Post",
          value: "conteudo",
        },
        {
          text: "Quantidade",
          value: "qtde",
          align: "right",
        },
      ];
    },
  },

  methods: {
    getTopPostsCurtidas() {
      this.loading = true;
      topPostsCurtidas()
        .then((response) => {
          this.top_posts_curtidas = response;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    reloadGet() {
      this.top_posts_curtidas = [];
      this.getTopPostsCurtidas();
    },
    openPost(item) {
      this.dialogPostCurtida = true;
      this.post_id = item.post_id;
    },
  },

  mounted() {
    this.getTopPostsCurtidas();
  },
};
</script>

<style></style>
